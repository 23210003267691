<template>
    <v-card
        class="text-center form-profile"
        flat
    >
        <v-card-title class="text-h5">
            <slot />
        </v-card-title>

        <v-tabs
            v-model="tab"
            class="tabs-profile"
            icons-and-text
            centered
            grow
        >
            <v-tab href="#tab-info">
                {{ $t('users.profile.tab.basicInfo.title') }}
                <v-icon>mdi-account-box</v-icon>
            </v-tab>
            <v-tab href="#tab-account">
                {{ $t('users.profile.tab.password.title') }}
                <v-icon>mdi-lock-reset</v-icon>
            </v-tab>
            <!--            <v-tab href="#tab-permission">
                {{ $t('users.profile.tab.permission.title') }}
                <v-icon>mdi-lock-question</v-icon>
            </v-tab>
            <v-tab href="#tab-role">
                {{ $t('users.profile.tab.role.title') }}
                <v-icon>mdi-account-multiple</v-icon>
            </v-tab>-->
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item
                value="tab-info"
            >
                <v-card flat>
                    <basic-info-form />
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-account"
            >
                <v-card flat>
                    <passwordChangeForm />
                </v-card>
            </v-tab-item>
            <!--            <v-tab-item
                value="tab-permission"
            >
                <v-card flat>
                    <permissionForm />
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-role"
            >
                <v-card flat>
                    <roleForm />
                </v-card>
            </v-tab-item>-->
        </v-tabs-items>

        <slot name="before-card-ends" />
    </v-card>
</template>
<script>

import basicInfoForm from '@/components/profile/basicInfoForm'
import passwordChangeForm from '@/components/profile/passwordChangeForm'
/*import permissionForm from '@/components/profile/permissionForm'
import roleForm from '@/components/profile/roleForm'*/

export default {
    components: { basicInfoForm, passwordChangeForm/*, permissionForm, roleForm*/ },
    data() {
        return {
            tab: true

        }
    },
    computed: {

    },
    created() {

    },
    methods: {

    }
}
</script>

<style lang="scss">

.form-profile {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.tabs-profile {
  .v-slide-group__prev {
    display: none !important;
  }

  .v-slide-group__next {
    display: none !important;
  }

  .v-tab {
    min-width: 75px !important;
    padding: 0 6px !important;
  }
}
</style>
